<template>
  <div class="meeting_agree_content">
    <div class="meeting_agree_content_main" v-html="agreementContent"></div>
    <base-footer background-color="#fff" v-if="!detail.laborAgreementSignUrl">
      <van-button type="primary" block round @click="clickSign">立即签署</van-button>
    </base-footer>
  </div>
</template>

<script>
  export default {
    name: 'MeetingAgreeContent',
    data: () => ({
      detail: {},
      agreementContent: ''
    }),
    computed: {
      user () {
        return this.$store.getters['user/getLoginInfo']
      }
    },
    created () {
      this.getDetail()
    },
    methods: {
      getDetail () {
        const meetingAgreeContent = sessionStorage.getItem('meetingAgreeContent')
        if (meetingAgreeContent) {
          const data = JSON.parse(meetingAgreeContent)
          if (data.agreementContent) {
            this.detail = data
            this.formatAgreement(data)
          } else {
            this.$router.go(-1)
          }
        } else {
          this.$router.replace('/meeting/agree/login')
        }
      },
      formatAgreement (data) {
        if (!this.user.id) {
          this.$router.replace('/meeting/agree/login')
          return
        }
        this.$api.meeting.getMeetingSpeakerInfo({
          meetingId: this.detail.meetingId,
          doctorId: this.user.userId
        }).then(res => {
          if (!res.code) {
            this.agreementContent = data.agreementContent
            let agreementContent = data.agreementContent

            // agreementContent = agreementContent.replaceAll('${department}', res.data.deptName || '')
            // agreementContent = agreementContent.replaceAll('${hospitalName}', res.data.hospitalName || '')
            // agreementContent = agreementContent.replaceAll('${bankNumber}', res.data.bankNumber || '')
            // agreementContent = agreementContent.replaceAll('${openBank}', res.data.bankName || '')
            // agreementContent = agreementContent.replaceAll('${idCardNumber}', res.data.idcard || '')
            // agreementContent = agreementContent.replaceAll('${doctorName}', res.data.name || '')
            // agreementContent = agreementContent.replaceAll('${signUrl}', '')
            agreementContent = agreementContent.replaceAll('${date}', this.$moment().format('YYYY年MM月DD日'))

            for (const key in res.data) {
              const str = '${' + key + '}'
              if (agreementContent.includes(str)) {
                agreementContent = agreementContent.replaceAll(str, res.data[key] || '')
              }
            }
            this.agreementContent = agreementContent
          }
        })
      },
      clickSign () {
        this.$router.push('/meeting/agree/sign')
      },
     
    }
  }
</script>

<style lang="less">
.meeting_agree_content {
  min-height: 100vh;
  background-color: rgba(245, 246, 247, 1);
  box-sizing: border-box;
  padding: 14px 16px;
  .meeting_agree_content_main {
    box-sizing: border-box;
    background-color: #FFF;
    border-radius: 6px;
    width: 100%;
    padding: 14px 10px;
    overflow: hidden;
    img {
      max-width: 100% !important;
    }
  }
  .van-button {
    height: 46px;
    border-radius: 3px;
    .van-button__text {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      color: #000000;
    }
  }
}
</style>